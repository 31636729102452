<template>
        <v-img
        :id="'goldie_' + uid"
        alt="Blue Tanks Goldies"
        class="primary lighten-2"
        
        contain
        :src="imgSrc"
        transition="scale-transition"
        width="100"
        height="100"
        aspect-ratio="1"
    ><template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        </v-img>

</template>

<script>
import axios from 'axios';
export default {
    props: {
        id: {
            type: Number,
            default: null,

        }
    },
    data: () => ({
        uid: null,
        imgSrc: ''
    }),
    mounted () {
        this.uid = this._uid;
        this.getGoldie();
    },
    methods: {
        getGoldie() {
            axios.get('/goldies/' + this.id)
            .then(response => {
                console.log(response);
                this.imgSrc = response.data.image;
                console.log(this.imgSrc)
            });
        }
    }
}
</script>