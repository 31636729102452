<template>
    <v-img
        :id="'goldie_' + uid"
        alt="Blue Tanks Goldies"
        class="mb-5"
        :style="position"
        contain
        src="/images/goldie.png"
        transition="scale-transition"
        width="100"
    />
</template>

<script>
export default  {
  name: "Goldie",
  components: {
  
  },
  data: () => ({
      uid: 0,
      left: 0,
      top: 50,
      direction: 'right',
      verticalDirection: "down",
      verticalSpeed: 50,
      horizontalSpeed: 35
  }),
  created() {
      this.uid = this._uid;
    
  },
  mounted() {
    let parentWidth = document.getElementById('goldie_' + this.uid).parentNode.clientWidth;
    
    this.top = this.random(100, 0);
    this.left = this.random(parentWidth, 0);
    this.direction = this.random(1, 0) ? 'left' : 'right';
    this.verticalDirection = this.random(1, 0) ? 'up' : 'down';
    this.move();
    this.rotate();
    this.adjustSpeed();
  },
  computed: {
      position () {
          let style = 'position: absolute; left: ' + this.left +'px; top: ' + this.top + 'px; ';
          if(this.direction === 'right') {
           
                style += 'transform:scaleX(-1) ';
                if(this.verticalDirection === "up") {
                    if(this.top < 10){
                        style += 'rotate(' + this.top + 'deg);';
                    } else if (this.top > 90) {
                        style += 'rotate(' + (100 - this.top) + 'deg);'
                    } else {
                        style += 'rotate(10deg); ';
                    }
                }
                if(this.verticalDirection === "down") {
                    if(this.top < 10){
                        style += 'rotate(-' + this.top + 'deg);';
                    }else if(this.top > 90) {
                        style += 'rotate(-' + (100 - this.top) + 'deg);'
                    } else {
                        style += 'rotate(-10deg); ';
                    }
                    
                }
          }else {
              style += "transform:"
            if(this.verticalDirection === "up") {
                if(this.top < 10) {
                    style += 'rotate(' + this.top + 'deg); ';
                }else if(this.top > 90) {
                        style += 'rotate(' + (100 - this.top) + 'deg);'
                } else {
                    style += 'rotate(10deg); '
                }
                
            }
            if(this.verticalDirection === "down") {
                  if(this.top < 10) {
                    style += 'rotate(-' + this.top + 'deg); ';
                } else if(this.top > 90) {
                        style += 'rotate(-' + (100 - this.top) + 'deg);'
                } else {
                     style += 'rotate(-10deg); '
                }
                
            }
          }
        
          return style
      }
  },
    methods: {
         move() {
            if(this.direction === "right") {
                this.moveRight();
            } else {
                this.moveLeft();
            }
            if(this.verticalDirection === "up") {
                this.moveUp();
            }else {
                this.moveDown();
            }
            setInterval(() => {
                if(this.random(1, 0)){
                    this.verticalDirection = this.random(1, 0) ? "up" : "down";
                }
                if(this.random(1,0)){
                    this.direction = this.random(1,0) ? "left" : "right";
                }
            }, this.random(5000, 1500));
      },
      moveLeft() {
          this.left--;
          if(this.direction === "left" && this.left > 0) {
              setTimeout(() => {
                this.moveLeft();
              }, this.horizontalSpeed);
          } else {
              this.direction = "right";
              this.moveRight();
          }
      },
      moveRight() {
          this.left++;
          if (this.direction === "right" && this.left < document.getElementById('goldie_' + this.uid).parentNode.clientWidth - 100) {
              setTimeout(() => {
                  this.moveRight();
              }, this.horizontalSpeed);
          }else {
              this.direction = 'left';
              this.moveLeft();
          }
      },
      moveUp() {
          this.top--;
          if(this.verticalDirection === "up" && this.top > 0){
              setTimeout(() => {
                  this.moveUp();
              }, this.verticalSpeed);
          } else {
              this.verticalDirection = "down";
              this.moveDown();
          }
      },
      moveDown() {
           this.top++;
          if(this.verticalDirection === "down" && this.top < 100){
              setTimeout(() => {
                  this.moveDown();
              }, this.verticalSpeed);
          } else {
              this.verticalDirection = "up";
              this.moveUp();
          }
      },
      rotate() {

      },
      adjustSpeed() {
            this.verticalSpeed = this.random(60, 20);
            this.horizontalSpeed = this.random(15, 1)
          setInterval(()=> {
              this.verticalSpeed = this.random(60, 20);
              this.horizontalSpeed = this.random(15, 1)
          },this.random(3000, 1200));
      },
      random(max, min) {
          return Math.floor(Math.random() * (max - min + 1) + min);
      }
  }

}
</script>
